﻿var BIDTOOLKI = BIDTOOLKI || {};

BIDTOOLKI.toggleAccordion = function (e) {
    e.preventDefault();
    var $this = $(e.target);

    if ($this.closest('.list-accordion').hasClass('single-open')) {

        if ($this.next().hasClass('show')) {
            $this.next().removeClass('show');
            $this.next().slideUp(350);
            $this.removeClass('open');

        } else {

            $this.parent().parent().find('li .accordion-inner').removeClass('show');
            $this.parent().parent().find('li .accordion-inner').slideUp(350);
            $this.parent().parent().find('.open').removeClass('open');
            $this.next().toggleClass('show');
            $this.toggleClass('open');
            $this.next().slideToggle(350);
        }
    } else {
        $this.next().toggleClass('show');
        $this.toggleClass('open');
        $this.next().slideToggle(350);
    }
};

BIDTOOLKI.SearchPageAfterLoad = (function () {//Doesnt need deferring as executed on doc.ready with ajax loaded Search
    function VariationFromMemberPrice(related) {
        this.ProductId = related.ProductId;
        this.DisplayPriceWithCurrency = related.DisplayPriceWithCurrency;
        this.DisplayPriceWithCurrencyIncVAT = related.DisplayPriceWithCurrencyIncVAT;
        this.PriceAfterCacheBack = related.PriceAfterCacheBack;
        this.DisplayPriceWithCurrencyM2 = related.DisplayPriceWithCurrencyM2
        this.DisplayPriceWithCurrencyIncVATM2 = related.DisplayPriceWithCurrencyIncVATM2;
    }

    //Show member variations from price if available and member cookie present
    var renderVariationFromMemberPrices = function RenderVariationFromMemberPrices() {
        if (BID.isMemberLoggedIn()) {
            //Gather MemVariationFrom Data
            $("span[id^=offerprice-]").each(function () {
                const related = /*JSON.parse(*/$(this).data("related")/*)*/;
                if (related) {
                    const VariationFromMember = new VariationFromMemberPrice(related);
                    if (VariationFromMember.DisplayPriceWithCurrency)
                        $('span#offerprice-' + VariationFromMember.ProductId).html(VariationFromMember.DisplayPriceWithCurrency);
                    if (VariationFromMember.DisplayPriceWithCurrencyIncVAT)
                        $('span#offerpriceexvat-' + VariationFromMember.ProductId).html(VariationFromMember.DisplayPriceWithCurrencyIncVAT);
                    if (VariationFromMember.PriceAfterCacheBack)
                        $('span#offerpriceACashback-' + VariationFromMember.ProductId).html(VariationFromMember.PriceAfterCacheBack);
                    if (VariationFromMember.displayPriceWithCurrencyM2)
                        $('span#offerpriceM2-' + VariationFromMember.ProductId).html(VariationFromMember.displayPriceWithCurrencyM2);
                    if (VariationFromMember.displayPriceWithCurrencyIncVATM2)
                        $('span#offerpriceexvatM2-' + VariationFromMember.ProductId).html(VariationFromMember.displayPriceWithCurrencyIncVATM2);
                }
            });
        }
    }

    //Show member refurb price if available and member cookie present
    var renderRefurbFromMemberPrices = function renderRefurbFromMemberPrices() {
        if (BID.isMemberLoggedIn()) {
            $("a[id^=refurb-prod-]").each(function () {
                const related = /*JSON.parse(*/$(this).data("related")/*)*/;
                if (related) {
                    if (related.lowestMemPriceIncVat)
                        $(this).children('span').html(related.lowestMemPriceIncVat)
                }
            });
        }
    }
    return {
        renderVariationFromMemberPrices: renderVariationFromMemberPrices,
        renderRefurbFromMemberPrices: renderRefurbFromMemberPrices
    };

})();

$(function ($) {
    /* Accordion */
    $('.toggle-accordion').on('click',BIDTOOLKI.toggleAccordion);

    /* Tabs */
    $('ul.tabs-navigation li').on('click',function () {
        var tab_id = $(this).attr('data-tab');

        if (tab_id.indexOf("techspec") < 0) {
            $('ul.tabs-navigation li').removeClass('active');
            $('.tab-content').removeClass('active');
        } else {
            $('ul.tabs-techspec li').removeClass('active');
        }

        $(this).addClass('active');
        $("#" + tab_id).addClass('active');

        if (tab_id == 'tab-dimensions') $("#techspec-main-review").addClass('active'); // show main tech spec when clicking tech spec tab
    });
    /** Product Tiles **/
    $('.card-product-tile').each(function () {
        if (!$(this).parent().hasClass('swiper-wrapper')) {
            if ($(this).parent().hasClass('desktop-5')) {
                $(this).wrapAll('<div class="b-col-6 b-col-md-25 margin-bottom-1" />');
            }
            if ($(this).parent().hasClass('desktop-6')) {
                $(this).wrapAll('<div class="b-col-6 b-col-md-3 b-col-lg-2 margin-bottom-1" />');
            }
            if ($(this).parent().hasClass('desktop-4')) {
                $(this).wrapAll('<div class="b-col-6 b-col-md-3 margin-bottom-1" />');
            }
        }
        if ($(this).parent().parent().hasClass('swiper-container')) {
            $('.swiper-wrapper').css('height', 'auto');
        }
    });

    $('.card-product-tile .contenttilecta a').each(function () {
        var parentWrapper = $(this).parent().parent();
        $(this).html("Shop now &raquo;").addClass('button button-small cover-link');
    });
    /** Filter **/
    var $items = $('.filter-items-wrapper .filter-item');
    var $none = $('.no-filter-results');
    var fade = 350;

    function filterContent() {
        var catName1 = $(this).find('option:selected').data('name');
        $('.filter-category-name').text(catName1);
        var catName2 = $(this).find('option:selected').text();
        $('.cat-name2').text(catName2);

        var dataLink = $(this).find('option:selected').attr("data-link");

        $('a.no-result-link').attr("href", dataLink);

        var word = $(this).find('option:selected').val();

        var hasMatches = $items
            .hide()
            .filter('.' + word)
            .stop(true, false)
            .show(fade)
            .length;

        if (hasMatches) {
            $none.hide();
        } else {
            $none.show();
        }

    }
    $('#filter-selector').on('change', filterContent);

    /* Scroll To */
    $(".scroll-button").on('click',function () {
        var position = $(this).attr("data-offset-position");
        var destination = $(this).attr("data-scroll-target");
        $('html, body').animate({ scrollTop: $('#' + destination).offset().top - + position }, 700);
    });

    /* Slide In Panel */
    getSlideInPanels();

    /* Swiper Carousels */
    initSwiper();

});

function initSwiper() {
    if ($ === undefined || $ === 'undefined') {
        $ = jQuery;
    }
    $('.bidSwiperMulti').each(function () {
        var desktopSlides = $(this).data('desktop-slides');
        var mobileSlides = $(this).data('mobile-slides');
        var swiper2 = new Swiper(".bidSwiperMulti", {
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            scrollbar: {
                el: ".swiper-scrollbar"
            },
            loop: false,
            slidesPerView: mobileSlides,
            spaceBetween: 24,
            breakpoints: {

                768: {
                    slidesPerView: 4
                },
                1024: {
                    slidesPerView: desktopSlides
                }
            }
        });
    });
    $('.bidSwiper').each(function () {
        var swiper1 = new Swiper(".bidSwiper", {

            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            scrollbar: {
                el: ".swiper-scrollbar"
            },
            loop: false,
            slidesPerView: 1,
            observer: true,
            observeParents: true
        });
    });

    /*
     * Source: https://stackoverflow.com/questions/34863788/how-to-check-if-an-element-has-been-loaded-on-a-page-before-running-a-script
     * Wait for an element before resolving a promise
     * @param {String} querySelector - Selector of element to wait for
     * @param {Integer} timeout - Milliseconds to wait before timing out, or 0 for no timeout              
    */
    function waitForElement(querySelector, timeout) {
        return new Promise((resolve, reject) => {
            var timer = false;
            if (document.querySelectorAll(querySelector).length) return resolve();
            const observer = new MutationObserver(() => {
                if (document.querySelectorAll(querySelector).length) {
                    observer.disconnect();
                    if (timer !== false) clearTimeout(timer);
                    return resolve();
                }
            });
            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
            if (timeout) timer = setTimeout(() => {
                observer.disconnect();
                reject();
            }, timeout);
        });
    }

    waitForElement(".swiper", 0).then(function () {
        $(".swiper").css('visibility', 'visible');
    });
    /* Scroll To */
    $(".scroll-button").on('click',function () {
        var position = $(this).attr("data-offset-position");
        var destination = $(this).attr("data-scroll-target");
        $('html, body').animate({ scrollTop: $('#' + destination).offset().top - + position }, 700);
    });

    /* SCROLLING TABS */

    $('.tab-swiper-wrapper').each(function () {
        var swiper3 = new Swiper(".tab-swiper-wrapper", {
            slidesPerView: "auto",
            spaceBetween: 0,
            autoHeight: true,
            slideToClickedSlide: true,
            watchOverflow: true,
        });
    });
}

function getSlideInPanels() {
    var panelTriggers = document.getElementsByClassName('slide-in-panel-trigger');
    if (panelTriggers.length > 0) {
        for (var i = 0; i < panelTriggers.length; i++) {
            (function (i) {
                var panelClass = 'slide-in-panel-' + panelTriggers[i].getAttribute('data-panel'),
                    panel = document.getElementsByClassName(panelClass)[0];
                panelTriggers[i].addEventListener('click', function (event) {
                    event.preventDefault();
                    addClass(panel, 'slide-in-panel--is-visible');
                    if (panel.classList.contains('slide-in-panel--from-left')) {
                        document.body.setAttribute("style", "overflow: hidden;");
                    } else {
                        document.body.setAttribute("style", "overflow: hidden; padding-right: 17px");
                    }


                });
                panel.addEventListener('click', function (event) {
                    if (hasClass(event.target, 'slide-in-panel-close') || hasClass(event.target, panelClass)) {
                        event.preventDefault();
                        removeClass(panel, 'slide-in-panel--is-visible');
                        setTimeout(function () {
                            document.body.removeAttribute('style');
                        }, 500);

                    }
                });
            })(i);
        }
        setFullscreenLoader('getRefurbSlideoutContent');
    }
}

function setAjaxLoader() {
    var $loading = $('#load_overlay').hide();
    $loading.fadeIn(300);
    $("body").addClass("overflow-y-hidden");
    $(document).ajaxComplete(function (event, xhr, settings) {
        if (settings.url.includes('getRefurbSlideoutContent')) {
            removeAjaxLoader();
        }
    });
}

function setInfiniteScrollLoaders() {
    $(document).ajaxSend(function (e, xhr, settings) {
        if (settings.url.includes('productsOnly=True')) {
            // $("#loadmore_button").hide(); // class button uses !important so hide won't work, disable used instead
            $("#infinitescroll_loader").show();
        }
    });
    $(document).ajaxComplete(function (event, xhr, settings) {
        if (settings.url.includes(window.location.pathname) && settings.url.includes('productsOnly=True')) {
            $("#infinitescroll_loader").hide();
        }
    });
}

function removeAjaxLoader() {
    var $loading = $('#load_overlay').hide();
    $loading.fadeOut(300);
    $("body").removeClass("overflow-y-hidden");
}

function supports_history_api() {
    return !!(window.history && history.pushState);
}

function waitForImages() {
    waitForElm('.offerImage:not([src])').then((elm) => {
        $('.offerImage:not([src])').each(function () {
            var $img = $(this);
            var imgsrc = $img.attr('data-original');
            $img.attr('src', imgsrc);
        });
    });
}

function sendSearchPageReadyEvent() {
    dataLayer.push({ 'event': 'searchPageReady' });
}

function pageLoaded(docReady = false, setAjaxListeners = true) {
    if (typeof waitForImages === "function") {
        waitForImages();
    }
    /*if (cachedProductAvailabilities) {
        cachedProductAvailabilities = null;
    }*/
    if (typeof getAvailability === "function") {
        getAvailability();
    }
    if (typeof getMobileAvailability === "function") {
        getMobileAvailability();
    }
    BIDTOOLKI.SearchPageAfterLoad.renderVariationFromMemberPrices();
    BIDTOOLKI.SearchPageAfterLoad.renderRefurbFromMemberPrices();
    if (!docReady) {
        if (typeof initCMS === "function") {
            initCMS();
        }
        getSlideInPanels();
        initSwiper();
    }
    if (setAjaxListeners) {
        $(document).off('ajaxStop').on('ajaxStop', function () {
            setListeners();
        });
    }
}

function updateResultCount(currentlyShowing, totalRecords) {
    var resultCountHTML = '<p class="margin-y-15">Showing <b>' + currentlyShowing + '</b> of <b>' + totalRecords + '</b> products</p>';
    $('div#ResultsCount').html(resultCountHTML);

    var loadMoreButton = $('#loadmore_button');
    if (loadMoreButton) {
        if (currentlyShowing >= totalRecords) {
            loadMoreButton.attr('style', 'display:none !important');
        }
        else {
            loadMoreButton.show();
        }
    }
}

function getProductListHTMLFromSessionStorage(productsdiv) {
    var urlPath = window.location.pathname + window.location.search;
    var productListHTML = JSON.parse(sessionStorage.getItem('infiniteScrollHTML'));
    if (productListHTML && productListHTML[urlPath] && productsdiv) {
        $(productsdiv).html(productListHTML[urlPath]);
        pageLoaded();
        var scrollPosition = sessionStorage.getItem('infiniteScrollPosition');
        if (scrollPosition) {
            window.scrollTo(0, scrollPosition);
        }
    }
}

function setListeners() {
    $('#ajax_SearchNav a, .sr_pagination a, #breadcrumbs a')
        .not('a[href="/"], a[href="#"], a[href^="/content/"], a[href^="/c/"]')
        .off('click')
        .on('click', function (e) {
            var href = $(this).attr("href");
            if (href) {
                e.preventDefault();
                searchLoadAjax(href);
            }
        });
}

function removePageNumber(queryString) {
    var urlPartsArray = queryString.split('?');
    if (urlPartsArray[1]) {
        queryString = removeURLQuery(urlPartsArray[1], 'pageNumber');
        return urlPartsArray[0] + (queryString.length > 0 ? '?' : '') + queryString;
    }
    else {
        return queryString;
    }
}
function removeURLQuery(urlQueryString, queryNameToRemove) {
    var urlQueryArray = urlQueryString.split('&');
    var index = urlQueryArray.findIndex(function (item) {
        return item.includes(queryNameToRemove);
    });
    if (index > -1) {
        urlQueryArray.splice(index, 1);
    }
    return urlQueryArray.join('&');
}

function waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

function removeLinks() {
    var links = ['canonical', 'prev', 'next'];
    links.forEach(link => $("head link[rel='" + link + "']").remove());
}
function setLink(rel, value) {
    $("head link[rel='" + rel + "']").first().remove()
    $('head').append('<link rel="' + rel + '" href="' + value + '">');
}

function setMetaProperty(key, value) {
    $("meta[property='" + key + "']").attr("content", value);
}


function hasClass(el, className) {
    if (el.classList) return el.classList.contains(className);
    else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
}

function addClass(el, className) {
    if (el.classList) el.classList.add(className);
    else if (!hasClass(el, className)) el.className += " " + className;
}

function removeClass(el, className) {
    if (el.classList) el.classList.remove(className);
    else if (hasClass(el, className)) {
        var reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
        el.className = el.className.replace(reg, ' ');
    }
}